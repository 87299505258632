import { useState } from 'react'
import { Platform } from 'react-native'
import { Speaker } from '../../domain/models/interfaces/speaker'
import delayPromise from '../../helpers/delayPromise'
import { UseSpeakersResult } from './useSpeakers'

const fetchSpeakers = async (): Promise<Speaker[]> => {
  if (Platform.OS !== 'web') {
    return []
  }

  try {
    const devices = await navigator.mediaDevices.enumerateDevices()
    const speakers = devices
      .filter((device) => device.kind === 'audiooutput')
      .map((device) => ({
        label: device.label || `Speaker ${device.deviceId.slice(0, 5)}...`,
        object: device,
      }))
    return speakers
  } catch (error) {
    throw new Error('Failed to fetch speakers')
  }
}

export const useSpeakers = (): UseSpeakersResult => {
  const [loading, setLoading] = useState(false)
  const [speakers, setSpeakers] = useState<Speaker[]>([])
  const [error, setError] = useState<string | null>(null)

  const refresh = async () => {
    // Não carrega speakers se não for web
    if (Platform.OS !== 'web') {
      setSpeakers([])
      return
    }

    setLoading(true)
    try {
      const fetchedSpeakers = await delayPromise(fetchSpeakers(), 500)
      setSpeakers(fetchedSpeakers)
      setError(null)
    } catch (error) {
      await delayPromise(Promise.resolve(), 500)
      setSpeakers([])
      setError(error.message)
    }
    setLoading(false)
  }

  return {
    loading,
    speakers,
    error,
    refresh,
  }
}
