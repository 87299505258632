import { useRef, useState } from 'react'
import {
  connect,
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteTrack,
  RemoteVideoTrack,
  Room,
} from 'twilio-video'

import { Camera, NO_CAMERA } from '../../../domain/models/interfaces/camera'
import {
  Microphone,
  NO_MICROPHONE,
} from '../../../domain/models/interfaces/microphone'
import { Speaker } from '../../domain/models/interfaces/speaker'
import {
  AudioStream,
  UseMeeting,
  VideoStream,
  VideoStreamType,
} from './useMeeting'

const useMeeting: UseMeeting = (token: string) => {
  const [connected, setConnected] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const room = useRef<Room | null>(null)
  const microphoneTrack = useRef<LocalAudioTrack | null>(null)
  const cameraTrack = useRef<LocalVideoTrack | null>(null)
  const screenTrack = useRef<LocalVideoTrack | null>(null)
  const [audio, setAudio] = useState<AudioStream[]>([])
  const [video, setVideo] = useState<VideoStream[]>([])

  const join = async () => {
    // connect to the room and store the reference
    const twilioRoom = await connect(token, { audio: false, video: false })
    room.current = twilioRoom
    setConnected(true)
    setError(null)

    // handle track subscription
    twilioRoom.on('trackSubscribed', handleTrackSubscribed)
    twilioRoom.on('trackUnsubscribed', handleTrackUnsubscribed)
  }

  const leave = async () => {
    // disconnect from the room and clear the reference
    await room.current?.disconnect()
    setConnected(false)
    room.current = null
  }

  const setMicrophone = async (microphone: Microphone) => {
    // unpublish the current microphone track, if it exists
    if (microphoneTrack.current) {
      room.current.localParticipant.unpublishTrack(microphoneTrack.current)
      microphoneTrack.current.stop()
      microphoneTrack.current = null
    }

    // if we're setting NO_MICROPHONE, we're done
    if (!microphone || microphone === NO_MICROPHONE) return

    // turn the microphone id into a track, publish it, and save the reference
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: microphone.object.deviceId },
    })
    const track = stream.getAudioTracks()[0]
    const audioTrack = new LocalAudioTrack(track)
    await room.current.localParticipant.publishTrack(audioTrack)
    microphoneTrack.current = audioTrack
  }

  const microphoneOff = () => {
    // disable the current microphone track
    microphoneTrack.current?.disable()
  }

  const microphoneOn = () => {
    // enable the current microphone track
    microphoneTrack.current?.enable()
  }

  const setCamera = async (camera: Camera) => {
    // unpublish the current camera track, if it exists
    if (cameraTrack.current) {
      room.current.localParticipant.unpublishTrack(cameraTrack.current)
      cameraTrack.current.stop()
      cameraTrack.current = null
    }

    // if we're setting NO_CAMERA, we're done
    if (!camera || camera === NO_CAMERA) return

    // turn the camera id into a track, publish it, and save the reference
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: camera.object.deviceId },
    })
    const track = stream.getVideoTracks()[0]
    const videoTrack = new LocalVideoTrack(track, { name: 'camera' })
    await room.current.localParticipant.publishTrack(videoTrack)
    cameraTrack.current = videoTrack
  }

  const cameraOff = () => {
    // disable the current camera track
    cameraTrack.current?.disable()
  }

  const cameraOn = () => {
    // enable the current camera track
    cameraTrack.current?.enable()
  }

  const screenShareOff = async () => {
    if (screenTrack.current) {
      screenTrack.current.mediaStreamTrack.stop()
    }
    try {
      room.current.localParticipant.unpublishTrack(screenTrack.current)
    } catch (error) {
      console.error('Error unpublishing screen track:', error)
    }
    screenTrack.current = null
  }

  const screenShareOn = async () => {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      video: { frameRate: 15 },
    })
    const track = new LocalVideoTrack(stream.getTracks()[0], { name: 'screen' })
    track.mediaStreamTrack.onended = screenShareOff // Listen to the ended event on the media track
    await room.current.localParticipant.publishTrack(track)
    screenTrack.current = track
  }

  const setSpeaker = async (speaker: Speaker) => {
    if (!speaker) return

    console.log('Setting speaker in useMeeting:', speaker)
    const audioElements = document.querySelectorAll('audio')
    const promises = Array.from(audioElements).map((element: any) => {
      if (element.setSinkId && speaker.object) {
        return element.setSinkId(speaker.object.deviceId).catch((error) => {
          console.error('Error setting audio output device:', error)
        })
      }
      return Promise.resolve()
    })

    await Promise.all(promises)
  }

  const handleTrackSubscribed = (track: RemoteTrack) => {
    if (track.kind === 'audio') {
      handleAudioTrackSubscribed(track as RemoteAudioTrack)
    } else if (track.kind === 'video') {
      handleVideoTrackSubscribed(track as RemoteVideoTrack)
    }
  }

  const handleAudioTrackSubscribed = (track: RemoteAudioTrack) => {
    // create an audio stream from the track
    const stream: AudioStream = {
      id: track.sid,
      play: () => {
        track.attach().play()
      },
      stop: () => {
        track.detach().forEach((el) => el.remove())
      },
    }

    // add the new stream to the audio state
    setAudio((prev) => [...prev, stream])
  }

  const handleVideoTrackSubscribed = (track: RemoteVideoTrack) => {
    // create an video stream from the track
    const stream: VideoStream = {
      id: track.sid,
      attach: (el: HTMLVideoElement) => {
        el.srcObject = track.attach().srcObject
      },
      detach: () => {
        track.detach().forEach((el) => el.remove())
      },
      type: track.name as VideoStreamType,
    }

    // add the new stream to the video state
    setVideo((prev) => [...prev, stream])
  }

  const handleTrackUnsubscribed = (track: RemoteTrack) => {
    if (track.kind === 'audio') {
      handleAudioTrackUnsubscribed(track as RemoteAudioTrack)
    } else if (track.kind === 'video') {
      handleVideoTrackUnsubscribed(track as RemoteVideoTrack)
    }
  }

  const handleAudioTrackUnsubscribed = (track: RemoteAudioTrack) => {
    // remove the audio stream matching the track sid
    setAudio((prev) => prev.filter((stream) => stream.id !== track.sid))
  }

  const handleVideoTrackUnsubscribed = (track: RemoteVideoTrack) => {
    // remote the video stream matching the track sid
    setVideo((prev) => prev.filter((stream) => stream.id !== track.sid))
  }

  const noop = async () => {}
  const actions = room.current
    ? {
        join,
        leave,
        setMicrophone,
        microphoneOff,
        microphoneOn,
        setCamera,
        cameraOff,
        cameraOn,
        setSpeaker,
        screenShareOff,
        screenShareOn,
      }
    : {
        join,
        leave: noop,
        setMicrophone: noop,
        microphoneOff: noop,
        microphoneOn: noop,
        setCamera: noop,
        cameraOff: noop,
        cameraOn: noop,
        setSpeaker: noop,
        screenShareOff: noop,
        screenShareOn: noop,
      }

  return {
    connected,
    error,
    audio,
    video,
    screenTrack: screenTrack.current,
    setSpeaker,
    ...actions,
  }
}

export default useMeeting
