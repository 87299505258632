import { useBreakpointValue } from 'native-base'

import { useEffect, useRef } from 'react'
import DesktopRoom from '../../components/liveGroup/room30/DesktopRoom'
import MobileRoom from '../../components/liveGroup/room30/MobileRoom'
import secureScreen from '../../hooks/authentication/secureScreen'
import useIntercom from '../../hooks/useIntercom'
import { AudioStream } from '../../hooks/useMeeting'
import { useRoomContext } from '../../providers/Room30Provider'

const RoomScreen30 = ({ isLobbyRoom }) => {
  const { audio, speaker } = useRoomContext()
  const { update } = useIntercom()
  const breakpoint = useBreakpointValue({
    base: 'mobile',
    lg: 'browser',
  })

  const audioRefs = useRef<Record<string, AudioStream>>({})

  useEffect(() => {
    audio.forEach((stream) => {
      if (audioRefs.current[stream.id]) return

      audioRefs.current[stream.id] = stream

      console.log('Playing audio stream:', stream.id)
      stream.play()

      if (breakpoint === 'browser' && speaker?.object) {
        console.log(
          'Applying speaker to new audio stream:',
          speaker.object.deviceId
        )
        const audioElements = document.querySelectorAll('audio')
        audioElements.forEach((element: any) => {
          if (element.setSinkId) {
            element
              .setSinkId(speaker.object.deviceId)
              .catch((error) =>
                console.error('Error setting audio output:', error)
              )
          }
        })
      }
    })

    return () => {
      Object.values(audioRefs.current).forEach((stream) => {
        console.log('Stopping audio stream:', stream.id)
        stream.stop()
      })
      audioRefs.current = {}
    }
  }, [audio, speaker, breakpoint])

  useEffect(() => {
    update()
  }, [])

  return breakpoint === 'mobile' ? (
    <MobileRoom />
  ) : (
    <DesktopRoom isLobbyRoom={isLobbyRoom} />
  )
}

export default secureScreen(RoomScreen30)
