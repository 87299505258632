import { Text } from 'native-base'

const Caption = ({
  children,
  textAlign = null,
  lineHeight = null,
  fontWeight = 'medium',
  color = 'text.500',
  mb = null,
}) => {
  return (
    <Text
      textAlign={textAlign}
      lineHeight={lineHeight}
      fontSize="xs"
      fontWeight={fontWeight}
      color={color}
      mb={mb}>
      {children}
    </Text>
  )
}

export default Caption
