import { Box, Flex } from 'native-base'
import { Platform } from 'react-native'
import AttendeeRole from '../../../../../domain/enums/AttendeeRole'
import { useRoomContext } from '../../../providers/Room30Provider'
import FormLabel from '../../_shared/form/FormLabel'
import CameraPicker from '../CameraPicker'
import MicrophonePicker from '../MicrophonePicker'
import SpeakerPicker from '../SpeakerPicker'
import PermissionCheck from './PermissionCheck'

const DeviceSettings = () => {
  const { me } = useRoomContext()

  const isModerator = me?.role === AttendeeRole.Moderator

  return (
    <Flex mb="5">
      <PermissionCheck isModerator={isModerator} />
      <Box>
        <FormLabel color="muted.500">Microphone</FormLabel>
        <MicrophonePicker />
      </Box>
      {Platform.OS === 'web' && (
        <Box mt={2}>
          <FormLabel color="muted.500">Speaker</FormLabel>
          <SpeakerPicker />
        </Box>
      )}
      {isModerator && (
        <Box mt={2}>
          <FormLabel color="muted.500">Camera</FormLabel>
          <CameraPicker />
        </Box>
      )}
    </Flex>
  )
}

export default DeviceSettings
