import { HStack, Select, Spinner, Text, VStack } from 'native-base'
import { useEffect, useState } from 'react'
import { useSpeakersContext } from '../../providers/SpeakerProvider'
import FaIcon from '../_shared/FaIcon'

const SpeakerPicker = () => {
  const { loading, error, speakers, refresh, selectSpeaker, selectedSpeaker } =
    useSpeakersContext()

  const [selectedValue, setSelectedValue] = useState(
    selectedSpeaker?.label || ''
  )

  useEffect(() => {
    setSelectedValue(selectedSpeaker?.label || '')
  }, [selectedSpeaker])

  useEffect(() => {
    if (speakers.length === 0) refresh()
  }, [])

  const handleSelect = (selectedLabel: string) => {
    const speaker = speakers.find((speaker) => speaker.label === selectedLabel)
    if (speaker) selectSpeaker(speaker)
  }

  const picker = (
    <VStack space={4}>
      <HStack
        alignItems="center"
        justifyContent="space-between"
        space={4}
        height={10}>
        {error && <FaIcon name="exclamation-triangle" color="error.500" />}
        <Select
          flex={1}
          selectedValue={selectedValue}
          accessibilityLabel="Select Speaker"
          placeholder="Select Speaker"
          size="lg"
          onValueChange={handleSelect}>
          {speakers.map((speaker) => (
            <Select.Item
              key={speaker.label}
              label={speaker.label}
              value={speaker.label}
            />
          ))}
        </Select>
        <FaIcon name="rotate" onPress={() => refresh()} />
      </HStack>
    </VStack>
  )

  const spinner = (
    <HStack
      alignItems="center"
      justifyContent="flex-start"
      space={4}
      height={16}>
      <Spinner />
      <Text>Loading speakers...</Text>
    </HStack>
  )

  return loading ? spinner : picker
}

export default SpeakerPicker
