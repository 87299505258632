import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import useStorage from '../../../domain/services/useStorage'
import { Speaker } from '../../domain/models/interfaces/speaker'
import { useSpeakers, UseSpeakersResult } from '../hooks/useSpeakers'

export interface UseSpeakersContext extends UseSpeakersResult {
  selectSpeaker: (speaker: Speaker) => void
  selectedSpeaker: Speaker | null
  reset: () => void
}

const SpeakersContext = createContext<UseSpeakersContext | undefined>(undefined)

export const useSpeakersContext = (): UseSpeakersContext => {
  const context = useContext(SpeakersContext)
  if (!context) {
    throw new Error('useSpeakersContext must be used within a SpeakersProvider')
  }
  return context
}

interface SpeakersProviderProps {
  children: ReactNode
}

export const SpeakersProvider: React.FC<SpeakersProviderProps> = ({
  children,
}) => {
  const { loading, speakers, error, refresh } = useSpeakers()
  const [savedSpeaker, setSavedSpeaker] = useStorage('@speaker', null)
  const [selectedSpeaker, setSelectedSpeaker] = useState<Speaker | null>(null)

  useEffect(() => {
    refresh()
  }, [])

  const selectSpeaker = (speaker: Speaker) => {
    setSelectedSpeaker(speaker)
    setSavedSpeaker(speaker)
  }

  const reset = () => {
    setSavedSpeaker(null)
  }

  useEffect(() => {
    if (speakers.length === 0) return
    if (selectedSpeaker && selectedSpeaker === savedSpeaker) return

    const matchingSpeaker = speakers.find(
      (speaker) => speaker.object?.deviceId === savedSpeaker?.object?.deviceId
    )

    selectSpeaker(matchingSpeaker ?? speakers[0])
  }, [speakers, savedSpeaker])

  const context: UseSpeakersContext = {
    loading,
    speakers,
    error,
    refresh,
    selectSpeaker,
    selectedSpeaker,
    reset,
  }

  return (
    <SpeakersContext.Provider value={context}>
      {children}
    </SpeakersContext.Provider>
  )
}
