import { Box, Button, Center, Flex, Text } from 'native-base'
import { NO_SPEAKER } from '../../../../domain/models/interfaces/speaker'
import { useMicrophonesContext } from '../../../providers/MicrophoneProvider'
import { useSpeakersContext } from '../../../providers/SpeakerProvider'
import Caption from '../../_shared/text/Caption'
import TextLink from '../../_shared/text/TextLink'

const JoinRoom = ({ handlePress }) => {
  const { selectedMicrophone } = useMicrophonesContext()
  const { selectedSpeaker } = useSpeakersContext()
  const hasMicrophone =
    selectedMicrophone?.name !== 'No microphone' && selectedMicrophone
  const hasSpeaker = selectedSpeaker && selectedSpeaker !== NO_SPEAKER

  return (
    <>
      <Flex>
        <Text color="primary.900" fontWeight="bold">
          Don't forget...
        </Text>
        {hasMicrophone ? (
          <>
            <Text color="primary.900">• Your voice is key</Text>
            <Text color="primary.900">• Sharing is encouraged</Text>
            <Text color="primary.900">• Come with an open mind</Text>
            <Text color="primary.900">• Invest in yourself</Text>
          </>
        ) : (
          <>
            <Text color="primary.900">• You're "Chat Only"</Text>
            <Text color="primary.900">• Sharing is encouraged</Text>
            <Text color="primary.900">• Come with an open mind</Text>
            <Text color="primary.900">
              • Good work–you showed up for yourself today
            </Text>
          </>
        )}
      </Flex>
      <Center pt="84px">
        <Button
          onPress={handlePress}
          colorScheme="primary"
          width="100%"
          my="3"
          isDisabled={!hasSpeaker}>
          Join Room
        </Button>
        {!hasSpeaker && (
          <Caption color="error.500" mb="3">
            Please select a speaker to join the room
          </Caption>
        )}
        <Box maxWidth="250">
          <Caption textAlign="center">
            By joining, you agree to Cabana by Even Health's
            <TextLink
              href="https://www.yourcabana.com/terms-of-service"
              fontWeight="bold"
              color="text.500">
              Terms of Service
            </TextLink>
            and
            <TextLink
              href="https://www.yourcabana.com/privacy-policy"
              color="text.500"
              fontWeight="bold">
              Privacy Policy
            </TextLink>
            .
          </Caption>
        </Box>
      </Center>
    </>
  )
}

export default JoinRoom
